import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterEvent, NavigationStart, NavigationEnd, NavigationError, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { DataService } from '../services/data.service';

@Injectable()
export class AuthGuard implements CanActivate {
    outputF  = false;
    activeMenu: any;
    notAdminPages = ['/admins'];
    notPamxBrokerPages = ['/quotes', '/grid-quotes', '/clients', '/admins', '/mail-templates', '/mail-signatures', '/quote-irpm','/expired-quotes','automated-quotes', '/old-list'];
    notBrokerPages = ['/quotes', '/grid-quotes','/brokers', '/clients', '/admins', '/mail-templates', '/mail-signatures', '/quote-irpm','/expired-quotes','automated-quotes', '/old-list'];
    notClientPages = ['/quotes', '/grid-quotes', '/brokers', '/clients', '/admins', '/broker-quotes','/wbroker-quote', '/broker', '/mail-templates', '/mail-signatures','/expired-quotes','automated-quotes', '/old-list'];
    quoteIdPages = ['/broker-quotes','/wbroker-quote' ,'/quote-summary', '/quote-policy', '/quote-underwriting', '/insured-info', '/quote-irpm', '/quote-history', '/quote-claims', '/eo-quote-summary', '/contractors-eco'];
    constructor(private router: Router, public dataService: DataService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const user = localStorage.getItem('u_id');
        const userRole = localStorage.getItem('u_ur_id');
        const userWholesaler = localStorage.getItem('wholesalebroker');
        const canhaveUserBroker = localStorage.getItem('canhaveUserBroker');
        //Url storage
        if(typeof localStorage.getItem('currentUrl') != undefined){
            localStorage.setItem('previousUrl', localStorage.getItem('currentUrl'));
            localStorage.setItem('currentUrl', state.url);
        }else{
            localStorage.setItem('currentUrl', state.url);
        }
        //Breadcums URL's
        let currentURLlocal = localStorage.getItem('currentUrl');
        let renewalQuotesList = '0';
        let expiredQuotesList = '0';
        let automatedList = '0';
        if(currentURLlocal.includes("/claims/")){
            currentURLlocal = '/claims';
        }else if(currentURLlocal.includes("/audits/")){
            currentURLlocal = '/audits';
        }else if(currentURLlocal.includes("/inspections/")){
            currentURLlocal = '/inspections';
        }else if(currentURLlocal == "/renewal-quotes"){
            renewalQuotesList = '1';
        }else if(currentURLlocal == "/expired-quotes"){
            expiredQuotesList = '1';
        }else if(currentURLlocal == "/automated-quotes"){
            automatedList = '1';
        }
        this.dataService.changecurrentCurrentURL(currentURLlocal);
        this.dataService.changecurrentRenewalQuotesList(renewalQuotesList);
        this.dataService.changecurrentExpiredQuotesList(expiredQuotesList);
        this.dataService.changecurrentExpiredQuotesList(automatedList);
        //ENd
        //End
        if (typeof user != undefined && user != null && user != "") {
            let currentPath = state.url;

            if(this.quoteIdPages.includes(currentPath)){

                localStorage.setItem('userLoggedInLoad', '0');
                return true;
            }
            else{
                if(userRole != '1'){
                    if(localStorage.getItem('userLoggedInLoad') == '0'){
                        let checkValue = false;
                        
                        if(userRole == '2'){
                            checkValue =  this.notAdminPages.includes(currentPath);
                        }else if(userRole == '3'){
                            if(userWholesaler == '1' || canhaveUserBroker == '1'){
                                checkValue =  this.notPamxBrokerPages.includes(currentPath); 
                            }else{
                                checkValue =  this.notBrokerPages.includes(currentPath);
                            }
                           
                        }else if(userRole == '4'){
                            checkValue =  this.notClientPages.includes(currentPath);
                        }
                        if (checkValue == false) {
                            return true;
                        }
                    }else{
                        localStorage.setItem('userLoggedInLoad', '0');
                        return true;
                    }
                }else{
                    return true;
                }
            }
        }
        this.dataService.changeCurrentHeaderActive('1');
        this.dataService.currentHeaderActive.subscribe(status => this.activeMenu = status);
        this.router.navigate(['/home']);
        return false;
    }

}